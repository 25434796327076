import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { APPFLOWS } from '../../../../constants/applicationConstants';
import { DataTableHoc, Loader, ErrorFallback } from 'smart-react';
import { GridColumnsList } from '../Components/Filter/FilterElements';
import DataColumns from '../Components/DataColumns/DataColumns';
import {
  ROW_LAYOUTS,
  PAGE_SIZE,
} from '../../../../constants/applicationConstants';
import { deleteFilter, applyFilter, saveFilters } from 'smart-react';
const BaseAppFlowDataTable = React.lazy(
  () => import('../Components/DataTable/DataTable'),
);
const AppFlowDataTable = DataTableHoc(BaseAppFlowDataTable);

/**
 * AppFlowDetailArgument main screen component
 * Displays a data table with filtering, sorting, and editing capabilities for application flows.
 *
 * @component
 * @returns {React.ReactElement} The AppFlowDetailArgument component.
 */
const AppFlow = () => {
  const [userData, setUserData] = React.useState([]);
  const dataColumnsArray = DataColumns({});
  // used to check API is ready to call
  const [isReady, setIsReady] = React.useState(false);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className='container mx-auto'>
        <React.Suspense fallback={<Loader />}>
          <AppFlowDataTable
            deleteFilter={deleteFilter}
            applyFilter={applyFilter}
            saveFilters={saveFilters}
            moduleName={APPFLOWS}
            dataColumns={dataColumnsArray}
            gridColumnsList={GridColumnsList}
            dataTableName={APPFLOWS}
            setIsReady={setIsReady}
            isReady={isReady}
            rowLayoutConfigName={`${APPFLOWS}.DataGrid.RowLayout`}
            pageLengthConfigName={`${APPFLOWS}.DataGrid.ItemsPerPage`}
            dataSet={userData}
            setDataSet={setUserData}
            initialSort={{
              field: 'id',
              dir: 'desc',
            }}
            isCardonMobileView={false}
            total={process.env.DefaultLimit}
            defaultRowLayout={process.env.RowLayout}
            rowLayouts={ROW_LAYOUTS}
            pageSizes={PAGE_SIZE}
            isDefaultSlider={true}
            contentSliderTitle={'App Flow'}
          />
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default AppFlow;
